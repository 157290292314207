import { request as axios } from '@/util/request';

export interface ParamName {
  id: number;
  text: string;
  value_list?: ParamValue[];
}

interface ParamValue {
  id: number;
  text: string;
  parent_id: number;
}

const API = {
  param: '/boss/product/parameter',
  value: '/boss/product/parameter/value',
};

function formatParamData(data: any): ParamName[] {
  return data.map((item: any) => {
    return {
      id: item.id,
      text: item.name,
      value_list: item.parent.length > 0 ? formatParamValue(item.parent) : null,
    };
  });
}

function formatParamValue(parents: any): ParamValue[] {
  return parents.map((item: any) => {
    return {
      id: item.id,
      text: item.value,
      parent_id: item.parent_id,
    };
  });
}

/**
 * 查询商品参数
 */
async function queryParam() {
  const res = await axios.get(API.param);
  return formatParamData(res.data);
}

/**
 * 添加新的商品参数名
 * @param name 商品参数名
 */
async function addParamName(name: string) {
  return await axios.post(API.param, { name });
}

/**
 * 更改商品参数名
 * @param id 商品参数 id
 * @param name 新的商品参数名
 */
async function updateParamName(id: number, name: string) {
  return await axios.put(`${API.param}/${id}`, { name });
}

/**
 * 添加新的商品参数值
 * @param parent_id 当前参数值所属的参数名 id
 * @param param_name 当前参数值所属的参数名
 * @param param_value 参数值
 */
async function addParamValue(parent_id: number, param_name: string, param_value: string) {
  return await axios.post(API.value, {
    id: parent_id,
    name: param_name,
    value: param_value,
  });
}

/**
 * 更改商品参数值
 * @param id 当前参数值 id
 * @param value 新的参数值
 */
async function updateParamValue(id: number, value: string) {
  return await axios.put(`${API.value}/${id}`, { value });
}

/**
 * 删除参数名或参数值
 * @param id 商品参数名或者参数值 id
 */
async function deleteParam(id: number) {
  return await axios.delete(`${API.param}/${id}`);
}

export default queryParam;
export { queryParam, addParamName, updateParamName, addParamValue, updateParamValue, deleteParam };
