
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getValidatorMap, Validate, Validator } from '@/mixin/validator';
import { queryRepo } from '@/api/repository/repo';
import { getSimpleCategory } from '@/api/ware/category';
import { queryParam } from '@/api/ware/param';
import { queryWareById, addWare, updateWare } from '@/api/ware/management';
import { changeLoading } from '@/util/decorators';
import moment from 'moment';
import CustomModal from '@/component/custom-modal.vue';
import EditableCell from '@/component/editable-cell.vue';
import MultipleImageUpload from '@/component/multiple-image-upload.vue';
import SamplePopover from '@/component/sample-popover.vue';
import SingleFileUpload from '@/component/single-file-upload.vue';
import { updateWareSort } from '@/api/ware/management';
import { queryProduct } from '@/api/factory/product';
import { watch } from '@vue/composition-api';
import { useCache } from '@/pinia/modules/wareRetain';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';

const uuidv4 = require('uuid/v4');
const _ = {
  findIndex: require('lodash/findIndex'),
};

enum OnlineTimeEnum {
  now = 1,
  custom,
  not,
}

interface ServiceList {
  value: string;
  help: string;
  status: string;
  is_valid: boolean;
}

// 上传文件的路径
const UPLOAD_DIR = {
  video: 'ware/video',
  carousel: 'ware/carousel',
  detail: 'ware/detail',
};

const SEARCH_TYPE = [
  {
    id: 0,
    value: 'U8编码',
  },
  {
    id: 1,
    value: '产品编号',
  },
];

@Component({
  name: 'WareRetain',
  components: {
    MultipleImageUpload,
    SingleFileUpload,
    CustomModal,
    SamplePopover,
    EditableCell,
  },
})
export default class WareDetail extends Mixins(Validate) {
  globalFunction = useGlobalFunction().globalFunction;
  pinia = useCache();
  // 表单布局
  form_item_layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  };
  // 轮播图布局
  image_item_layout = {
    labelCol: { span: 1 },
    wrapperCol: { span: 23 },
  };
  form: any = {
    title: '',
    subtitle: '',
    price: '',
    market_price: '',
    repo: [],
    category_id: [],
    is_recommend: 1,
    is_new: 2,
    cooling_mode: 1,
    video_url: '',
    banner_url_list: [],
    detail_url_list: [],
    feature_list: [],
    service_list: [],
    param_list: [],
    online_time_type: 1,
    online_time: '',
    sort: 1,
    plf_stock: undefined,
  };

  current: number = 0;
  validator_list: any[] = [
    {
      field: 'title',
      message: '请输入产品名称',
    },
    {
      field: 'subtitle',
      message: '请输入产品卖点',
    },
    {
      field: 'market_price',
      message: '请输入市场售价,应为最多两位小数的非负数',
      validate: (value: number): boolean => {
        return /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value + '');
      },
    },
    {
      field: 'price',
      message: '请输入商品价格,应为最多两位小数的非负数',
      validate: (value: number): boolean => {
        return /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value + '');
      },
    },
    {
      field: 'repo',
      validate: (value: string): boolean => true,
      message: '请添加发货仓库',
    },
    {
      field: 'category_id',
      validate: (value: string): boolean => !!value.length,
      message: '请选择商品分类',
    },
    {
      field: 'banner_url_list',
      validate: (value: string): boolean => !!value.length,
      message: '请上传轮播图',
    },
    {
      field: 'detail_url_list',
      validate: (value: string): boolean => !!value.length,
      message: '请上传详情图',
    },
    {
      field: 'online_time',
      message: '请选择上架时间',
      validate: (value: any, form: any): boolean => {
        if (form.online_time_type !== this.ONLINE_TIME.custom) {
          return true;
        }
        return !!value;
      },
    },
    {
      field: 'sort',
      message: '排序值应为>=0的整数',
      validate: (value: string): boolean => {
        return /^[0-9]\d*$/.test(value);
      },
    },
  ];
  all_list: any = [
    [
      {
        field: 'title',
        message: '请输入产品名称',
      },
      {
        field: 'subtitle',
        message: '请输入产品卖点',
      },
      {
        field: 'market_price',
        message: '请输入市场售价,应为最多两位小数的非负数',
        validate: (value: number): boolean => {
          return /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value + '');
        },
      },
      {
        field: 'price',
        message: '请输入商品价格,应为最多两位小数的非负数',
        validate: (value: number): boolean => {
          return /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value + '');
        },
      },
      {
        field: 'repo',
        validate: (value: string): boolean => true,
        message: '请添加发货仓库',
      },
      {
        field: 'category_id',
        validate: (value: string): boolean => !!value.length,
        message: '请选择商品分类',
      },
    ],
    [
      {
        field: 'banner_url_list',
        validate: (value: string): boolean => !!value.length,
        message: '请上传轮播图',
      },
      {
        field: 'detail_url_list',
        validate: (value: string): boolean => !!value.length,
        message: '请上传详情图',
      },
    ],
    [
      {
        field: 'online_time',
        message: '请选择上架时间',
        validate: (value: any, form: any): boolean => {
          if (form.online_time_type !== this.ONLINE_TIME.custom) {
            return true;
          }
          return !!value;
        },
      },
      {
        field: 'sort',
        message: '排序值应为>=0的整数',
        validate: (value: string): boolean => {
          return /^[0-9]\d*$/.test(value);
        },
      },
    ],
  ];
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);

  ONLINE_TIME = OnlineTimeEnum;
  MAX_TITLE_LENGTH: number = 40;
  MAX_SUBTITLE_LENGTH: number = 46;
  MAX_CAROUSEL_LENGTH: number = 5;

  is_loading: boolean = false; // 页面的加载状态
  is_edit: boolean = false;
  is_show_param: boolean = false;
  is_show_service: boolean = false;
  is_show_feature: boolean = false;
  video_visible: boolean = false;

  single_image_url: any = ''; // 单图片上传时的图片路径
  multi_images_list: any = []; // 多图片上传时的图片路径列表

  preview_image: string = ''; // 预览图片的路径
  video_urls: string = ''; //视频链接
  preview_visible: boolean = false; // 预览图片的模态框显示状态

  ware_id: number = 0; // 商品 id
  ware_category_list: any = [];
  param_name_list: any = [];
  param_value_list: any = [];

  banner_sample_img: string = require('@/asset/img/logo-default.png');
  serve_sample_img: string = require('@/asset/img/logo-default.png');
  feature_sample_img: string = require('@/asset/img/logo-default.png');
  param_sample_img: string = require('@/asset/img/logo-default.png');

  repo_modal: SimpleModal = {
    title: '添加仓库',
    visible: false,
    repo_list: [],
    selected_id_list: [],
    selected_data: [],
    temp: {}, // 缓存
  };
  binding_modal: SimpleModal = {
    title: '',
    visible: false,
    data: null,
  };
  bindingModal(val: any) {
    this.binding_modal.title = val.status ? '解绑门店' : '绑定门店';
    this.binding_modal.visible = true;
    this.binding_modal.data = val;
  }
  handleBindingModalCancel() {
    this.binding_modal.visible = false;
  }
  handleBindingModalOk() {
    this.binding_modal.visible = false;
    this.onRepoItemDelete(this.binding_modal.data);
  }

  //搜索条件映射
  get searchType() {
    return SEARCH_TYPE;
  }

  // 确认修改状态弹窗
  add_modal: any = {
    title: '',
    visible: false,
    is_loading: false,
  };

  seach: any = {
    search_type: 0,
    search_value: '',
  };

  next_page: number = 1;
  purchase_list: any = [];
  purchase_data = {
    current: 1,
  };
  selectedRowKeys: any = [];
  selectedRows: any = [];
  selectedOldRows: any = [];
  get UPLOAD_DIR(): any {
    return UPLOAD_DIR;
  }

  get repoModalRowSelection(): any {
    return {
      selectedRowKeys: this.repo_modal.selected_id_list,
      onSelect: (record: any, selected: boolean) => {
        if (selected) {
          if (this.repo_modal.selected_id_list.indexOf(record.id) === -1) {
            this.repo_modal.selected_data.push(record);
            this.repo_modal.selected_id_list.push(record.id);
          }
        } else {
          const index = this.repo_modal.selected_id_list.indexOf(record.id);
          this.repo_modal.selected_id_list.splice(index, 1);
          this.repo_modal.selected_data.splice(index, 1);
        }
      },
      onSelectAll: (selected: boolean, selected_rows: any, change_rows: any) => {
        if (selected) {
          this.repo_modal.selected_id_list = [
            ...new Set([
              ...this.repo_modal.selected_id_list,
              ...selected_rows.map((item: any) => item.id),
            ]),
          ];
        } else {
          this.repo_modal.selected_id_list = this.repo_modal.selected_id_list.filter(
            (id: number) => !change_rows.find((item: any) => item.id === id)
          );
        }
        this.repo_modal.selected_data = null;
        this.repo_modal.selected_data = selected_rows;
      },
    };
  }

  @Watch('form.service_list', { deep: true })
  onServiceListChange(service_list: ServiceList[]) {
    service_list.map((item: ServiceList) => {
      if (!item.value) {
        item.help = '请输入服务内容';
        item.status = 'error';
        item.is_valid = false;
      } else {
        item.help = '';
        item.status = 'success';
        item.is_valid = true;
      }
    });
  }
  @Watch('form.feature_list', { deep: true })
  onFeatureListChange(feature_list: any) {
    feature_list.map((item: any) => {
      if (!item.value) {
        item.help = '请输入产品亮点';
        item.status = 'error';
        item.is_valid = false;
      } else {
        item.help = '';
        item.status = 'success';
        item.is_valid = true;
      }
    });
  }
  back() {
    // this.$router.go(-1);

    this.$router.push({
      path: '/ware/management',
      query: { page: this.$route.query?.page || '1' },
    });
  }

  // 通用图片上传校验
  beforeCommonUpload(file: any) {
    if (file.size / 1024 / 1024 > 2) {
      this.$message.error('上传图片大小不能超过2M');
      return false;
    }
  }

  // 将上架时间转换为时间戳
  formatOnlineTime(online_time_type: number) {
    let online_time = '';
    switch (online_time_type) {
      case 1:
        online_time = (Date.now() / 1000).toFixed(); // 当前时间戳
        break;
      case 2:
        online_time = this.form.online_time.format('X'); // 选中的时间转换为时间戳
        break;
      case 3:
        online_time = '0'; // 暂不上架
        break;
      default:
        break;
    }
    return online_time;
  }

  getSendData() {
    const _repo = this.form.repo.map((repo: any) => {
      return {
        warehouse_id: repo.warehouse_id,
        sort: repo.sort,
        stock: repo.stock,
        sales: repo.sales,
        id: repo.id,
        status: repo.status,
      };
    });

    const goods_services = this.form.service_list.map((item: ServiceList) => item.value);
    const lightspot = this.form.feature_list.map((item: any) => ({ title: item.value }));
    const goods_parameter = this.form.param_list.map((item: any) => {
      return {
        param_name_id: item.param_name_id,
        param_value_id: item.param_value_id,
      };
    });
    this.form.banner_url_list = this.form.banner_url_list.map((item2: any) => {
      if (item2.indexOf('http') === -1) {
        return (item2 = `https:${item2}`);
      }
      return item2;
    });
    this.form.detail_url_list = this.form.detail_url_list.map((item2: any) => {
      if (item2.indexOf('http') === -1) {
        return (item2 = `https:${item2}`);
      }
      return item2;
    });
    const list: any = [];
    this.purchase_list.map((item: any) => {
      list.push({
        pd_id: item.id,
        pd_name: item.name,
        count: Number(item.count),
      });
    });
    this.form.video_url = this.form.video_url
      ? this.form.video_url.indexOf('http') === -1
        ? `https:${this.form.video_url}`
        : this.form.video_url
      : '';
    const send_data = {
      title: this.form.title,
      subtitle: this.form.subtitle,
      market_price: this.form.market_price * 100,
      price: this.form.price * 100,
      warehouse: _repo,
      category_id: this.form.category_id[this.form.category_id.length - 1],
      is_recommend: this.form.is_recommend,
      is_new: this.form.is_new,
      cooling_mode: this.form.cooling_mode,
      video_url: this.form.video_url,
      viewpager_url:
        this.form.banner_url_list.length === 1
          ? this.form.banner_url_list[0]
          : this.form.banner_url_list.join(),
      detail_image_url:
        this.form.detail_url_list.length === 1
          ? this.form.detail_url_list[0]
          : this.form.detail_url_list.join(),
      goods_services: goods_services,
      lightspot: lightspot,
      goods_parameter: goods_parameter,
      putaway_type: this.form.online_time_type,
      putaway_time: this.formatOnlineTime(this.form.online_time_type),
      sort: this.form.sort,
      plf_stock: this.form.plf_stock,
      pd: list,
    };

    console.log('send', send_data);
    return send_data;
  }

  // 显示模态窗，改变模态窗中的图片路径
  handlePreview(imageUrl: string) {
    this.preview_image = imageUrl;
    this.preview_visible = true;
  }
  // 关闭模态窗
  handlePreviewCancel() {
    this.preview_visible = false;
  }

  handleRepoModalCancel() {
    const _modal = this.repo_modal;
    _modal.selected_id_list = _modal.temp.selected_id_list;
    _modal.selected_data = _modal.temp.selected_data;
    _modal.visible = false;
  }

  // 确定添加仓库
  handleRepoModalOk() {
    this.repo_modal.selected_data.forEach((item: any) => {
      this.form.repo.push({
        ...item,
        sort: this.form.repo.length + 1,
        stock: 0,
        sales: 0,
        // 绑定状态
        status: 1,
        // 新增id为0
        id: 0,
        // 仓库id
        warehouse_id: item.id,
      });
    });
    this.repo_modal.selected_id_list.forEach((item: any) => {
      this.repo_modal.repo_list.splice(
        _.findIndex(this.repo_modal.repo_list, (item2: any) => item2.id === item),
        1
      );
    });
    this.repo_modal.selected_data = [];
    this.repo_modal.selected_id_list = [];
    this.repo_modal.visible = false;
  }

  // 排序值改变
  async handleCellChange(record: any, type: string, value: number) {
    /* if (!value||value<0) {
      this.$message.error('修改失败');
      return;
    }
    const res: any = await updateWareSort(record.id, value);
    if (res.status !== 200) {
      return this.$message.error(res.message);
    }
    this.$message.success('修改成功'); */
    if (!value) {
      return;
    }
    record[type] = value;
  }

  onAddRepo() {
    this.repo_modal.temp = {
      selected_id_list: [...this.repo_modal.selected_id_list],
      selected_data: [...this.repo_modal.selected_data],
    };
    this.repo_modal.visible = true;
  }

  // 删除仓库项
  onRepoItemDelete(record: any) {
    const record_id = this.is_edit ? record.warehouse_id : record.id;
    console.log(record);

    this.form.repo = this.form.repo.map((item: any) => {
      if (item.warehouse_id === record.warehouse_id) {
        // 设置绑定状态
        item.status = item.status === 1 ? 0 : 1;
      }
      return item;
    });
    this.repo_modal.selected_id_list = this.repo_modal.selected_id_list.filter(
      (id: number) => id !== record_id
    );
    this.repo_modal.selected_data = this.repo_modal.selected_data.filter(
      (item: any) => item.id !== record_id
    );
  }

  // 选择/不选择产品亮点
  onFeatureChange() {
    this.is_show_feature = !this.is_show_feature;
    if (!this.is_show_feature) {
      this.form.feature_list = null;
      this.form.feature_list = [];
    }
  }

  // 选择/不选择商品服务
  onServiceChange() {
    this.is_show_service = !this.is_show_service;
    if (!this.is_show_service) {
      this.form.service_list = null;
      this.form.service_list = [];
    }
  }

  // 选择/不选择商品参数
  onParamChange() {
    this.is_show_param = !this.is_show_param;
    if (!this.is_show_param) {
      this.form.param_list = null;
      this.form.param_list = [];
    }
  }

  // 选择商品分类
  onWareCategoryChange(value: any) {
    this.form.category_id = value;
  }

  // 添加商品服务
  onAddService() {
    const item = {
      value: '',
      help: '',
      status: 'success',
      is_valid: false,
    };
    this.form.service_list.push(item);
  }
  // 删除商品服务
  onDeleteService(index: string) {
    this.form.service_list.splice(index, 1);
  }

  // 添加产品卖点
  onAddFeature() {
    const item = {
      value: '',
      help: '',
      status: 'success',
      is_valid: false,
    };
    this.form.feature_list.push(item);
  }
  // 删除商品服务
  onDeleteFeature(index: string) {
    this.form.feature_list.splice(index, 1);
  }

  // 商品参数发生变化
  onParamNameSelectChange(record: any, id: number) {
    this.param_name_list.map((item: any) => {
      if (item.id === id) {
        record.param_value_list = item.value_list;
        record.param_value_id = undefined;
      }
    });
  }

  // 参数值发生变化
  onParamValueSelectChange(record: any, id: number) {
    record.param_value_id = id;
  }

  // 添加商品参数
  onAddParam() {
    const item: any = {
      id: uuidv4(),
      param_name_id: undefined,
      param_value_id: undefined,
      param_name_list: this.param_name_list,
      param_value_list: [],
    };
    this.form.param_list.push(item);
  }

  // 删除商品参数
  onParamDelete(index: string) {
    this.form.param_list.splice(index, 1);
  }
  lastStep() {
    console.log(1);
    this.validatorMap = getValidatorMap(this.all_list[this.current - 1]);
    this.current--;
  }
  @changeLoading(['is_loading'])
  async basic() {
    if (this.is_edit) {
      this.validatorMap = getValidatorMap(this.all_list[(this.current = 0)]);
      this.current;
      console.log(this.current);
      return;
    }
  }
  async picture() {
    if (this.is_edit) {
      this.validatorMap = getValidatorMap(this.all_list[(this.current = 1)]);
      this.current;
      console.log(this.current);
      return;
    }
  }

  async announce() {
    if (this.is_edit) {
      this.validatorMap = getValidatorMap(this.all_list[(this.current = 2)]);
      this.current;
      console.log(this.current);
      return;
    }
  }

  async product() {
    if (this.is_edit) {
      this.validatorMap = getValidatorMap(this.all_list[(this.current = 3)]);
      this.current;
      console.log(this.current);
      return;
    }
  }

  async submit() {
    if (this.current < 3) {
      if (!this.validateCommit()) {
        return;
      }
      this.validatorMap = getValidatorMap(this.all_list[this.current + 1]);
      this.current++;
      return;
    }

    let stock_is_zero = true;
    this.form.repo.forEach((item: any) => {
      if (item.stock < 0) {
        stock_is_zero = false;
      }
    });
    if (!stock_is_zero) {
      this.$message.error('请填写库存');
      return;
    }

    if (this.purchase_list.length > 0) {
      let flag: boolean = false;
      this.purchase_list.map((item: any) => {
        if (!item.count) {
          flag = true;
          return this.$message.error(`请添加${item.name}的商品数量`);
        }
      });
      if (flag) {
        return;
      }
    } else {
      return this.$message.error('请添加商品');
    }

    const no_params_arr = this.form.param_list.filter((item: any) => {
      return item.param_name_id && !item.param_value_id;
    });
    if (no_params_arr.length) {
      this.$message.error('请输入参数值');
      return;
    }
    if (this.is_edit) {
      // 编辑
      const res: any = await updateWare(this.ware_id, this.getSendData());
      if (res.status !== 200) {
        this.$message.error(res.message);
        return;
      }
    } else {
      // 新增
      const res: any = await addWare(this.getSendData());
      if (res.status !== 200) {
        this.$message.error(res.message);
        return;
      }
    }
    this.pinia.setCache(undefined);
    this.$message.success('提交成功');
    if (this.is_edit) {
      this.$router.push({
        path: '/ware/management',
        query: { page: this.$route.query?.page || '{id}' },
      });
    } else {
      this.$router.push({
        path: '/ware/management',
        query: { page: this.$route.query?.page || '1' },
      });
    }

    this.globalFunction.fetchWareList();
  }

  formatWareData(res: any) {
    for (let i = 0; i < res.warehouse.length; i++) {
      for (let j = 0; j < this.repo_modal.repo_list.length; j++) {
        if (res.warehouse[i].warehouse_id === this.repo_modal.repo_list[j].id) {
          this.form.repo.push({
            ...this.repo_modal.repo_list[j],
            stock: res.warehouse[i].stock,
            sales: res.warehouse[i].sales,
            sort: res.warehouse[i].sort,
            id: res.warehouse[i].id,
            status: res.warehouse[i].status,
            warehouse_id: res.warehouse[i].warehouse_id,
          });
          this.repo_modal.repo_list.splice(j, 1);
        }
      }
    }
    console.log(this.form.repo);

    // const service_list = JSON.parse(res.service_list);
    const param_list = res.param_list ? JSON.parse(res.param_list) : [];
    this.form.title = res.title;
    this.form.subtitle = res.subtitle;
    this.form.price = res.price;
    this.form.market_price = res.market_price;
    this.form.category_id = res.category_id;
    this.form.is_recommend = res.is_recommend;
    this.form.is_new = res.is_new;
    this.form.cooling_mode = res.cooling_mode;
    this.form.video_url = res.video_url;
    this.form.banner_url_list = res.banner_url_list;
    this.form.detail_url_list = res.detail_url_list;
    // 因为立即上架设置的是当前时间，在编辑状态下，页面初次进入是不会选中立即上架的
    // this.form.online_time_type = res.online_time ? OnlineTimeEnum.custom : OnlineTimeEnum.not;
    this.form.online_time_type = res.online_time_type;
    this.form.online_time = res.online_time_type === 2 ? moment(res.online_time * 1000) : undefined;
    this.form.sort = res.sort;
    this.form.plf_stock = res.plf_stock;

    // 转换商品服务格式
    this.form.service_list = JSON.parse(res.service_list).map((item: string) => {
      return {
        value: item,
        help: '',
        status: 'success',
      };
    });
    // this.form.service_list = res.service_list;
    if (this.form.service_list.length > 0) {
      this.is_show_service = true;
    }
    // 转换产品亮点格式
    this.form.feature_list = res.lightspot.map(item => {
      return {
        value: item.title,
        help: '',
        status: 'success',
      };
    });
    if (this.form.feature_list.length > 0) {
      this.is_show_feature = true;
    }
    console.log('service', this.form.service_list);
    console.log('feature', this.form.feature_list);
    console.log('lightspot', res.lightspot);

    // 转换商品参数格式
    this.form.param_list = param_list.map((item: any) => {
      let _param_value_list: any = [];
      this.param_name_list.map((name_item: any) => {
        if (name_item.id === item.param_name_id) {
          _param_value_list = name_item.value_list;
        }
      });
      return {
        id: uuidv4(),
        param_name_id: item.param_name_id,
        param_value_id: item.param_value_id,
        param_name_list: this.param_name_list,
        param_value_list: _param_value_list,
      };
    });
    if (this.form.param_list.length > 0) {
      this.is_show_param = true;
    }
  }

  // 获取商品详情数据
  async fetchWareData(id: number) {
    const res = await queryWareById(id);
    this.purchase_list = res.pd;
    this.formatWareData(res);
    this.form.price = this.form.price / 100;
    this.form.market_price = this.form.market_price / 100;
  }

  // 获取仓库列表
  @changeLoading(['is_loading'])
  async fetchRepoList() {
    const res: any = await queryRepo();
    //console.log(res);
    this.repo_modal.repo_list = res.map((item: any) => {
      return {
        id: item.repo_id,
        name: item.repo_name,
        address: item.repo_address,
      };
    });
  }
  watchVideo(url: string) {
    const full_url = url ? (url.indexOf('http') === -1 ? `https:${url}` : this.form.video_url) : '';
    console.log(full_url);
    this.video_urls = full_url;
    this.video_visible = true;
  }

  // 获取产品列表
  async fetchList(current: number = 1) {
    this.purchase_data = await queryProduct(current, { status: 1 });
    console.log(this.purchase_data);
  }

  async addWare() {
    this.add_modal.title = '添加商品';
    this.add_modal.visible = true;
    await this.fetchList(this.$route.query.page ? +this.$route.query.page : 1);
  }

  // table 勾选
  onSelectChange(selectedRowKeys: any, selectedRows: any) {
    const map = new Map();
    const newArr: any = [];
    this.selectedRowKeys = selectedRowKeys;
    if (this.selectedOldRows.length !== 0) {
      this.selectedOldRows = [...this.selectedOldRows, ...selectedRows];
      for (const item of this.selectedOldRows) {
        map.set(item.id, item);
      }
      this.selectedOldRows = [...map.values()];
      for (let i = 0; i < this.selectedRowKeys.length; i++) {
        for (let j = 0; j < this.selectedOldRows.length; j++) {
          if (this.selectedRowKeys[i] == this.selectedOldRows[j].id) {
            newArr.push(this.selectedOldRows[j]);
          }
        }
      }
      this.selectedRows = newArr;
    } else {
      this.selectedOldRows = [...selectedRows];
      this.selectedRows = selectedRows;
    }
    console.log(this.selectedOldRows);
  }

  // tag 删除勾选
  closeLog(id: any) {
    console.log(this.selectedRowKeys.indexOf(id));
    this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(id), 1);
    this.selectedOldRows.map((item: any, i: any) => {
      if (item.id == id) {
        this.selectedOldRows.splice(i, 1);
      }
    });
    this.selectedRows.map((item: any, i: any) => {
      if (item.id == id) {
        this.selectedRows.splice(i, 1);
      }
    });
    console.log(id, this.selectedRowKeys, this.selectedOldRows, this.selectedRows);
  }

  // 添加商品
  submitWare() {
    console.log(this.selectedRows);
    this.purchase_list = this.selectedRows;
    this.add_modal.visible = false;
  }

  cancelWare() {
    console.log('取消');
    this.selectedRows = [];
    this.selectedOldRows = [];
    this.selectedRowKeys = [];
    this.add_modal.visible = false;
  }

  // 搜索
  async query() {
    await this.fetchProductList();
  }
  @changeLoading(['is_loading'])
  async fetchProductList(current: number = 1) {
    const send_data: any = {
      u8_code: this.seach.search_type === 0 ? this.seach.search_value : '',
      id: this.seach.search_type === 1 ? this.seach.search_value : '',
      name: this.seach.name,
      status: 1,
    };
    for (const key in send_data) {
      if (send_data[key] === '') delete send_data[key];
    }
    this.purchase_data = await queryProduct(current, send_data);
  }

  // 搜索清空重置
  async resetFilter() {
    this.seach = {
      search_type: 0,
      search_value: '',
      u8_code: '',
      id: '',
      name: '',
    };
    await this.fetchList();
  }

  //分页
  paginationOption(data: any) {
    return {
      current: +data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }

  onTableChange(pagination: any) {
    this.next_page = pagination.current;
    this.fetchProductList(this.next_page);
  }

  @changeLoading(['is_loading'])
  async created() {
    this.pinia.setCache('WareRetain');
    this.validatorMap = getValidatorMap(this.all_list[0]);
    await this.fetchRepoList();
    this.ware_category_list = await getSimpleCategory(); // 获取商品分类列表
    this.ware_category_list = this.ware_category_list.filter((element: any) => {
      return element.children != undefined;
    });
    this.param_name_list = await queryParam(); // 获取商品参数列表
    if (this.$route.query.id) {
      this.ware_id = +this.$route.query.id;
      this.is_edit = true;
      await this.fetchWareData(this.ware_id);
    }
  }
}
