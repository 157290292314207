import { defineStore } from 'pinia'
import { ref } from 'vue'


export const useCache = defineStore('cache', () => {
   const cache=ref<string|undefined>()
   function setCache(val){
    cache.value = val
   }
   return {
    cache,
    setCache
   }
})