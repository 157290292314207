import { request as axios } from '@/util/request';
import {
  message,
} from 'ant-design-vue';
import moment from 'moment';
interface RepoSendData {
  name: string;
  /* province_id: number;
  city_id: number;
  district_id: number;
  head_address: string; */
  address: string;
  /* freight_type: number;
  freight: number;
  delivery_time: number; */
  knight_range: any;
  knight_freight: any;
  lon: any;
  lat: any;
  contact_mobile: any;
  printer_key: any;
  printer_number: any;
}

interface PostageSendData {
  warehouse_id: number;
  region_id: number;
  region_name: string;
  freight_type: number;
  freight: number;
  delivery_time: number;
}

const API = {
  repo: '/boss/warehouse/warehouse', // 仓库
  postage: '/boss/warehouse/freight', // 运费
  region: '/boss/warehouse/region',
  people:'/boss/warehouse/staff',//负责人接口
  repertory:'/boss/warehouse/pd', //门店库存
};

const status_text: any = {
  1: '正常',
  2: '停用',
};

function formatRepo(remote: any = {}): RemotePagination<RepoData> {
  return {
    current: remote.current || 1,
    limit: remote.limit || 10,
    detail: remote.detail || [],
    total: remote.total || 0,
    last: remote.last || 1,
  };
}

function formatRepoData(remote: any = {}): RepoData {
  return {
    repo_id: remote.id,
    repo_id_text: `S${remote.id}`,
    repo_name: remote.name,
    repo_address: remote.head_address + remote.address,
    province_id: remote.province_id + '',
    city_id: remote.city_id + '',
    district_id: remote.district_id + '',
    details: remote.address,
    common_postage_type: remote.freight_type,
    common_postage: remote.freight / 100,
    common_postage_text:
      remote.freight_type === 1 ? '包邮' : `¥ ${(remote.freight / 100).toFixed(2)}`,
    common_arrive_time: moment(remote.create_time*1000).format('YYYY-MM-DD HH:mm:ss') ,
    common_arrive_time_text: `${moment(remote.create_time*1000).format('YYYY-MM-DD HH:mm:ss')}`,
    status: remote.status,
    status_text: status_text[remote.status],
    area_data: formatRepoChildren(remote.parent),
    repo_phone:remote.contact_mobile,
    repo_money:remote.knight_freight/100,
    repo_fanwei:remote.knight_range/1000,
    lat:remote.lat,
    lon:remote.lon,
    printer_number:remote.printer_number,
    printer_key:remote.printer_key,
  };
}

function formatRepoChildren(children: any = []): AreaData[] {
  return children.map((child: any) => formatAreaData(child));
}

function formatAreaData(remote: any = {}): AreaData {
  return {
    id: remote.id,
    id_text: `A${remote.region_id}`,
    area_name: remote.region_name,
    repo_id: remote.warehouse_id,
    region_id: remote.region_id,
    area_postage: remote.freight / 100,
    area_postage_text:
      remote.freight_type === 1 ? '包邮' : `¥ ${(remote.freight / 100).toFixed(2)}`,
    area_postage_type: remote.freight_type,
    area_arrive_time: remote.delivery_time,
    area_arrive_time_text: `预计${remote.delivery_time}天内送达`,
  };
}

/**
 * 查询仓库列表带分页
 * @param current 当前页数
 * @param is_paginate 是否分页，默认 true
 */
async function queryRepoAndPaginate(current: number = 1,body?: any) {
  const res = await axios.get(API.repo, {
    params: {
      current,
      is_page: 1,
      ...body
    },
  });
  const repo = formatRepo(res.data);
  repo.detail = repo.detail.map((item: any) => formatRepoData(item));
  return repo;
}
/**
 * 查询负责人列表带分页
 * @param current 当前页数
 * @param is_paginate 是否分页，默认 true
 */
 async function people(body: any){
  const res = await axios.get(API.people, {
    params: {
      ...body
    },
  });
  return res.data
 }
/**
 * 查询所有仓库
 */
async function queryRepo() {
  const res = await axios.get(API.repo);
  if (res.status !== 200) {
    message.error((res as any).message);
  }
  if (res.data.length === 0) {
    return [];
  }
  return res.data.map((item: any) => formatRepoData(item));
}

/**
 * 添加仓库
 * @param send_data
 */
async function addRepo(send_data: RepoSendData) {
  return axios.post(API.repo, send_data);
}

/**
 * 编辑仓库
 * @param id 仓库 id
 * @param send_data
 */
async function updateRepo(id: number, send_data: RepoSendData) {
  return await axios.put(`${API.repo}/${id}`, send_data);
}

/**
 * 删除仓库
 * @param id 仓库 id
 */
async function deleteRepo(id: number) {
  return await axios.delete(`${API.repo}/${id}`);
}

/**
 * 停用仓库
 * @param id 仓库 id
 * @param is_stop 是否停用
 */
async function stopRepo(id: number, is_stop: boolean) {
  const status = is_stop ? 2 : 1;
  return await axios.get(`${API.repo}/${id}/edit`, { params: { status } });
}

/**
 * 添加区域运费
 * @param send_data
 */
async function addPostage(send_data: PostageSendData) {
  return await axios.post(API.postage, send_data);
}

/**
 * 编辑区域运费
 * @param id 区域运费 id
 * @param send_data
 */
async function updatePostage(id: number, send_data: PostageSendData) {
  return await axios.put(`${API.postage}/${id}`, send_data);
}

/**
 * 删除区域运费
 * @param id 区域运费 id
 */
async function deletePostage(id: number) {
  return await axios.delete(`${API.postage}/${id}`);
}
// 门店库存
async function getRepertory(body: any) {
  return await axios.get(API.repertory, {
    params: {
      ...body
    },
  });
}
export default queryRepoAndPaginate;
export {
  getRepertory,
  queryRepo,
  queryRepoAndPaginate,
  addRepo,
  updateRepo,
  deleteRepo,
  stopRepo,
  addPostage,
  updatePostage,
  deletePostage,
  people
};
