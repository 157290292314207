
import { Component, Prop, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import oss from 'baimahu/aliyun/oss';
const { VUE_APP_API: API } = process.env;
@Component
export default class SingleFileUpload extends Vue {
  @Prop({ type: String, default: '' }) api!: string;
  @Prop({ type: String, default: 'boss.niulandi.com' }) domain!: string;
  @Prop({ type: String, default: '' }) readonly dir!: string;
  @Prop({ type: String, default: '' }) readonly accept!: string;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @Prop({ type: Function }) readonly beforeUpload!: any;
  @Prop({ type: String, default: '' }) fileUrl!: string;
  loading: boolean = false;

  handleChange(info: any) {
    if (info.file.status === 'uploading') {
      this.loading = true;
      return;
    }
  }

  handleRemove() {
    this.$emit('update:fileUrl', '');
  }
  handleWatch() {
    this.$emit('watchVideo');
  }

  @changeLoading(['loading'])
  async request(obj: any) {
    const { src } = await oss.upload(`https://${API}/boss/common/oss/video`, obj.file, true);
    console.log(src);

    this.$emit('update:fileUrl', src);
  }
}
