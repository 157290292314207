
import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
  queryParam,
  addParamName,
  updateParamName,
  addParamValue,
  updateParamValue,
  deleteParam,
  ParamName,
} from '@/api/ware/param';
import { getValidatorMap, Validate, Validator } from '@/mixin/validator';
import { changeLoading } from '../../util/decorators';
import EditableCell from '@/component/editable-cell.vue';
import CustomModal from '@/component/custom-modal.vue';

interface AddModal {
  title: string;
  visible: boolean;
  is_loading: boolean;
}

interface ValueModal {
  parent_id: number;
  param_name: string;
  title: string;
  content: ValueModalContent[];
  visible: boolean;
  is_loading: boolean;
}

interface ValueModalContent {
  value: string;
  validator: ValueValidator;
}

interface ValueValidator {
  help: string;
  status: string;
  is_valid: boolean;
  validate: (value: any) => boolean;
}

const name_columns = [
  {
    title: '商品参数',
    dataIndex: 'param_name',
    key: 'param_name',
    scopedSlots: {
      customRender: 'param_name',
    },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: {
      customRender: 'operation',
    },
  },
];
const value_columns = [
  {
    title: '参数值',
    dataIndex: 'param_value',
    key: 'param_value',
    scopedSlots: {
      customRender: 'param_value',
    },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: {
      customRender: 'operation',
    },
  },
];

function genValueValidator(): ValueValidator {
  return {
    help: '',
    status: 'success',
    is_valid: false,
    validate: (value: string) => !!value,
  };
}

@Component({
  name: 'WareParameter',
  components: {
    EditableCell,
    CustomModal,
  },
})
export default class WareParameter extends Mixins(Validate) {
  form_item_layout: any = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };
  form: any = {
    param_name: '',
  };
  validator_list: any[] = [
    {
      field: 'param_name',
      message: '请填写参数名称',
    },
  ];
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  validator_field_list: any = [];

  is_loading: boolean = false;
  is_modal_loading: boolean = false;
  data: any = [];
  name_columns = name_columns;
  value_columns = value_columns;

  add_modal: AddModal = {
    title: '新增商品参数',
    visible: false,
    is_loading: false,
  };
  value_modal: ValueModal = {
    parent_id: 0,
    param_name: '',
    title: '',
    content: [],
    visible: false,
    is_loading: false,
  };
  tips_modal: any = {
    id: undefined,
    title: '为保障APP端正常展示，需满足以下条件才能成功删除该商品参数',
    visible: false,
    is_loading: false,
  };
  fail_modal: any = {
    title: '抱歉，因以下原因商品参数删除失败',
    visible: false,
    content: '',
  };

  @Watch('value_modal.content')
  onValueContentChange(content: ValueModalContent[]) {
    this.validator_field_list = content.map((item: ValueModalContent) => {
      return this.$watch(
        () => {
          return item.value;
        },
        new_val => {
          item.validator.is_valid = item.validator.validate(new_val);
          if (!item.validator.is_valid) {
            item.validator.help = '请填写参数值';
            item.validator.status = 'error';
          } else {
            item.validator.help = '';
            item.validator.status = 'success';
          }
        }
      );
    });
  }

  check() {
    const _content = this.value_modal.content;
    if (_content.length <= 0) {
      return false;
    }
    // 当打开弹窗，输入框的值没有变化时，不会触发 watch
    // 需要手动设置状态
    _content.map((item: ValueModalContent) => {
      if (!item.validator.is_valid) {
        item.validator.help = '请填写参数值';
        item.validator.status = 'error';
      }
    });
    return _content.every((item: ValueModalContent) => item.validator.is_valid);
  }

  // 确定添加参数名
  async handleAddNameOk() {
    if (!this.validateCommit()) {
      return;
    }
    this.add_modal.is_loading = true;
    const res = await addParamName(this.form.param_name);
    this.add_modal.is_loading = false;
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.$message.success('添加成功');
    await this.fetchParamData();
    this.handleAddNameCancel();
  }

  handleAddNameCancel() {
    this.add_modal.visible = false;
    this.form.param_name = '';
    this.$nextTick(() => {
      this.resetValidatorStatus();
    });
  }

  // 确定添加参数值
  @changeLoading(['is_loading'])
  async handleAddValueOk() {
    if (!this.check()) {
      // this.$message.error('请填入必填项');
      return;
    }
    const parent_id = this.value_modal.parent_id;
    const param_name = this.value_modal.param_name;
    const length = this.value_modal.content.length;
    this.value_modal.visible = false;
    for (let i = 0; i < length; i++) {
      const item = this.value_modal.content[i];
      const res: any = await addParamValue(parent_id, param_name, item.value);
      if (res.status !== 200) {
        this.$message.error(res.message);
        return;
      }
    }
    this.$message.success('添加成功');
    this.fetchParamData();
  }

  // 确定删除
  @changeLoading(['is_loading'])
  async handleTipsModalOk() {
    this.tips_modal.visible = false;
    const res = await deleteParam(this.tips_modal.id);
    if (res.status !== 200) {
      this.fail_modal.visible = true;
      this.fail_modal.content = (res as any).message;
      return;
    }
    this.$message.success('删除成功');
    this.fetchParamData();
  }

  // 更新参数名或参数值
  @changeLoading(['is_loading'])
  async handleCellChange(id: number, key: string, value: string) {
    let res: any = undefined;
    if (key === 'param_name') {
      if (value == '' || value == undefined) {
        this.$message.error('商品参数名称必填');
        return;
      } else {
        res = await updateParamName(id, value);
      }
    } else {
      res = await updateParamValue(id, value);
    }
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    await this.fetchParamData();
  }

  // 点击新增商品参数
  onAddName() {
    this.add_modal.visible = true;
  }

  // 点击新增参数值
  onAddValue(record: any) {
    this.value_modal.parent_id = record.id;
    this.value_modal.param_name = record.text;
    this.value_modal.title = `新增 ${record.text} 的参数值`;
    this.value_modal.content = [{ value: '', validator: genValueValidator() }];
    this.value_modal.visible = true;
  }

  // 点击删除
  onDelete(record: any) {
    this.tips_modal.id = record.id;
    this.tips_modal.visible = true;
  }

  // 点击增加参数值按钮
  onValueAdd() {
    this.value_modal.content.push({
      value: '',
      validator: genValueValidator(),
    });
  }

  @changeLoading(['is_loading'])
  async fetchParamData() {
    const res = await queryParam();
    this.data = res.map((item: ParamName) => {
      return {
        ...item,
        operation: '新增参数值',
      };
    });
  }

  mounted() {
    this.fetchParamData();
  }

  beforeDestroy() {
    this.validator_field_list.forEach((unwatch: () => void) => unwatch());
  }
}
