
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { getValidatorMap, Validate, Validator } from '@/mixin/validator';
import {
  addNewCategory,
  deleteCategory,
  updateCategory,
  queryWareCategory,
} from '@/api/ware/category';
import EditableCell from '@/component/editable-cell.vue';
import CustomModal from '@/component/custom-modal.vue';

interface AddModal {
  id: number;
  title: string;
  name: string;
  sort: number | string;
  level: number;
  visible: boolean;
  is_loading: boolean;
}

const columns = [
  {
    title: '排序值',
    dataIndex: 'sort',
    key: 'sort',
    width: '300px',
    scopedSlots: {
      customRender: 'sort',
    },
  },
  {
    title: '级别',
    dataIndex: 'level_text',
    key: 'level_text',
  },
  {
    dataIndex: 'name',
    key: 'name',
    slots: { title: 'name_title' },
    scopedSlots: {
      customRender: 'name',
    },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: {
      customRender: 'operation',
    },
  },
];

const MAX_LENGTH = 6;

@Component({
  name: 'WareCategory',
  components: {
    EditableCell,
    CustomModal,
  },
})
export default class WareCategory extends Mixins(Validate) {
  form_item_layout: any = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };
  form: any = {
    category_name: '',
    sort: '',
  };
  validator_list: any[] = [
    {
      field: 'category_name',
      message: '请填写分类名称',
    },
    {
      field: 'sort',
      message: '请填写排序值,要求>=0的整数',
    },
  ];
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  is_loading: boolean = false;
  MAX_LENGTH = MAX_LENGTH;
  data: any = [];
  category_list: any = [];
  columns: any = columns;
  // 添加分类弹窗
  add_modal: AddModal = {
    id: 0,
    title: '',
    name: '',
    sort: '',
    level: 1,
    visible: false,
    is_loading: false,
  };
  // 确认删除弹窗
  tips_modal: any = {
    title: '',
    visible: false,
    content: [],
    data: {},
    is_loading: false,
  };
  // 删除失败弹窗
  fail_modal: any = {
    title: '抱歉，因以下原因分类删除失败',
    visible: false,
    content: [],
  };
  inputs(record: any) {
    return (record.sort = record.sort.match(/\d+(\.\d{0,2})?/)
      ? record.sort.match(/\d+(\.\d{0,2})?/)[0]
      : '');
  }
  // 监听需要校验字段的变化而从改变 form 中的值
  @Watch('add_modal.name')
  onNameChange(value: string) {
    this.form.category_name = value;
  }
  @Watch('add_modal.sort')
  onSortChange(value: number) {
    this.form.sort = value;
  }

  // 清空模态框数据
  clearModal() {
    // 清除校验信息
    this.add_modal.visible = false;
    this.add_modal.name = '';
    this.add_modal.sort = '';
    this.$nextTick(() => {
      this.resetValidatorStatus();
    });
  }

  // 确认新增
  async handleAddModalOk() {
    // 校验
    if (!this.validateCommit()) {
      return;
    }
    const name = this.add_modal.name;
    const sort = this.add_modal.sort as number;
    const level = this.add_modal.level;
    const parent_id = this.add_modal.level === 1 ? 0 : this.add_modal.id;
    this.add_modal.is_loading = true;
    const res = await addNewCategory(name, sort, level, parent_id);
    this.add_modal.is_loading = false;
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.$message.success('添加成功');
    this.clearModal();
    await this.fetchWareCategory();
  }

  // 新增取消
  handleAddModalCancel() {
    this.clearModal();
  }

  // 确认删除
  async handleTipsModalOk() {
    this.tips_modal.is_loading = true;
    const res = await deleteCategory(this.tips_modal.data.id);
    this.tips_modal.is_loading = false;
    this.tips_modal.visible = false;
    if (res.status !== 200) {
      this.fail_modal.content = [...(res as any).message.split('\n')];
      this.fail_modal.visible = true;
      return;
    }
    await this.fetchWareCategory();
    this.$message.success('删除成功');
  }

  // 更新
  async handleCellChange(id: number, key: string, value: string) {
    let sort: any = undefined;
    let name = '';
    if (key === 'sort') {
      sort = +value;
      // sort = parseInt(value, 10);
      if (!/^[0-9]+$/.test(sort + '')) {
        this.$message.error('排序值须为>=0的整数值！');
        return;
      }
      /* if (sort === 0) {
        this.$message.error('排序值不能为0！');
        return;
      } */
      /* if(sort<0){
        this.$message.error('排序值需大于0！');
        return;
      } */
    } else {
      if (!value) {
        this.$message.error('分类名称必填');
        return;
      }
      name = value;
    }
    const res: any = await updateCategory(id, sort, name);
    if (res.status !== 200) {
      this.$message.error(res.message);
    }
    this.$message.success('修改成功');
    await this.fetchWareCategory();
  }

  onAddLevelOne() {
    this.add_modal.visible = true;
    this.add_modal.title = '新增一级分类';
    this.add_modal.level = 1;
  }

  onAddLevelTwo(record: any) {
    this.add_modal.visible = true;
    this.add_modal.title = `新增 ${record.name} 的二级分类`;
    this.add_modal.level = 2;
    this.add_modal.id = record.id;
  }

  // 点击删除
  onDelete(record: any) {
    if (record.children) {
      this.tips_modal.title = '为保障APP端正常展示，需满足以下条件才能成功删除该一级分类';
      this.tips_modal.content = ['没有商品使用该一级分类', '该一级分类下没有二级分类'];
    } else {
      this.tips_modal.title = '为保障APP端正常展示，需满足以下条件才能成功删除该二级分类';
      this.tips_modal.content = ['没有商品使用该二级分类'];
    }
    this.tips_modal.visible = true;
    this.tips_modal.data = record;
  }

  @changeLoading(['is_loading'])
  async fetchWareCategory() {
    const _data = await queryWareCategory();
    this.data = _data.map((item: any) => {
      return {
        ...item,
        operation: '新增二级分类',
      };
    });
  }

  async mounted() {
    await this.fetchWareCategory();
  }
}
