
import moment from 'moment';
interface Form {
  product_id: number | undefined;
  product_name: string | undefined;
  ware_type: string[];
  warehouse: string | undefined;
  recommend: string | undefined;
  relate: string | undefined;
  status: string | undefined;
  create_time: string[];
  price_min: number | undefined;
  price_max: number | undefined;
  stock_min: number | undefined;
  stock_max: number | undefined;
  sales_min: number | undefined;
  sales_max: number | undefined;
}
import { Component, Vue } from 'vue-property-decorator';
import {
  STATUS_LIST,
  STATUS_LIST_MAP,
  RECOMMEND_LIST,
  RECOMMEND_LIST_MAP,
  RELATE_LIST,
  RELATE_LIST_MAP,
} from './constant';
import CustomModal from '@/component/custom-modal.vue';
import EditableCell from '@/component/editable-cell.vue';
import {
  queryWare,
  queryWareConnect,
  disconnect,
  wareConnect,
  updateWareStatus,
  updateWareSort,
  updateStock,
} from '@/api/ware/management';
import { queryRepo } from '@/api/repository/repo';
import { getSimpleCategory } from '@/api/ware/category';
import { changeLoading } from '../../util/decorators';
import { Form as AntForm } from 'ant-design-vue';
import { useMain } from '@/pinia/index';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';

const _ = {
  findIndex: require('lodash/findIndex'),
};
@Component({
  name: 'WareManagement',
  components: {
    CustomModal,
    EditableCell,
  },
})
//WareManagement
export default class WareManagement extends Vue {
  setGlobalFunction = useGlobalFunction().setGlobalFunction;
  searchForm: any;
  searchParams: any;
  moment: any = moment;
  form: any = {
    product_id: '', // 商品ID
    product_name: '', // 商品名称
    ware_category_id: [0], // 商品分类
    warehouse_id: 0, // 发货门店
    is_recommend: 0, // 是否推荐
    is_relevance: 0, // 是否关联
    status: 0, // 上架状态
    create_time: [], // 创建时间
    price_min: '', // 价格
    price_max: '', // 价格
    stock_min: '', // 剩余库存
    stock_max: '', // 剩余库存
    sales_min: '', // 总销量
    sales_max: '', // 总销量
  };
  status_list: any = STATUS_LIST;
  status_list_map: any = STATUS_LIST_MAP;
  recommend_list: any = RECOMMEND_LIST;
  recommend_list_map: any = RECOMMEND_LIST_MAP;
  relate_list: any = RELATE_LIST;
  relate_list_map: any = RELATE_LIST_MAP;
  is_loading: boolean = false;
  is_show_ware_modal: boolean = false;
  next_page: number = 1;
  ware_table: RemotePagination<WareData> = {
    detail: [],
    current: 1,
    last: 1,
    limit: 1,
    total: 10,
  };
  ware_category_list: any = [];
  repo_list: any = [];
  selected_id_list: any = [];
  selected_data: any = [];
  all_ware_data: any = [];
  modal_ware_table_data: any[] = [];
  pagination = useMain().pagination;
  get wareCurrentPageSelectedIdList(): number[] {
    const selected_id_list = this.selected_id_list;
    const current_page_ware_data_list = this.all_ware_data[this.next_page];
    const current_page_selected_id_list: number[] = current_page_ware_data_list
      ? current_page_ware_data_list
          .filter((item: any) => !!selected_id_list.find((id: number) => id === item.id))
          .map((item: any) => item.id)
      : [];
    return current_page_selected_id_list;
  }
  get wareRowSelection(): object {
    return {
      selectedRowKeys: this.wareCurrentPageSelectedIdList,
      onSelect: (record: any, selected: boolean) => {
        if (selected) {
          if (this.selected_id_list.indexOf(record.id) === -1) {
            this.selected_data.push(record);
            this.selected_id_list.push(record.id);
          }
        } else {
          const index = this.selected_id_list.indexOf(record.id);
          this.selected_id_list.splice(index, 1);
          this.selected_data.splice(index, 1);
        }
      },
      onSelectAll: (selected: boolean, selected_rows: any, change_rows: any) => {
        if (selected) {
          this.selected_id_list = [
            ...new Set([...this.selected_id_list, ...selected_rows.map((item: any) => item.id)]),
          ];
        } else {
          this.selected_id_list = this.selected_id_list.filter(
            (id: number) => !change_rows.find((item: any) => item.id === id)
          );
        }
        this.selected_data = null;
        this.selected_data = selected_rows;
      },
    };
  }
  onTableChange(pagination: any, filters, sorter) {
    this.next_page = pagination.current;
    // this.fetchFilterWareList(this.next_page);
    let sort: any = sorter.order; // 排序类型，正序/倒序/不排序
    console.log(sorter);
    if (sorter.columnKey == 'stock') {
      if (sort == 'ascend') {
        sort = 'stock_asc';
      } else if (sort == 'descend') {
        sort = 'stock_desc';
      } else {
        sort = undefined;
      }
      this.fetchFilterWareList(this.next_page, sort, '');
    } else {
      if (sort == 'ascend') {
        sort = 'sales_asc';
      } else if (sort == 'descend') {
        sort = 'sales_desc';
      } else {
        sort = undefined;
      }
      this.fetchFilterWareList(this.next_page, '', sort);
    }
  }
  paginationOption(data: RemotePagination<WareData>) {
    return {
      total: data.total || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      pageSize: data.limit || 1,
      current: Number(data.current) || 1,
    };
  }
  resetFilter() {
    this.form = {
      product_id: '',
      product_name: '',
      ware_category_id: [0],
      warehouse_id: 0,
      is_recommend: 0,
      is_relevance: 0,
      status: 0,
      create_time: [],
      price_min: '',
      price_max: '',
      stock_min: '',
      stock_max: '',
      sales_min: '',
      sales_max: '',
    };
    this.searchForm.resetFields();
    this.ware_table.current = 1;
    this.query();
  }

  //数字输入框change事件
  money_true: boolean = false; //价格提示框显隐属性
  surplus_number: boolean = false; //剩余库存提示框显隐
  all_sales: boolean = false; //总销量库存提示框显隐
  onInputNumberChange(value: number, type: string) {
    console.log(this.form.price_min);
    const ret = /^(-?\d+)(\.\d{1,2})?$/; //正则定义两位小数
    const res = /(^[1-9]\d*$)/; //正整数正则
    if (type == 'price_min' || type == 'price_max') {
      //传进来价格参数判断
      if (
        this.form.price_min != '' &&
        this.form.price_min != null &&
        (this.form.price_min < 0 || !ret.test(this.form.price_min))
      ) {
        this.money_true = true;
      } else if (
        this.form.price_max != '' &&
        this.form.price_max != null &&
        (this.form.price_max < 0 || !ret.test(this.form.price_max))
      ) {
        this.money_true = true;
      } else {
        this.money_true = false;
      }
      this.form[type] = +value.toFixed(2);
    } else if (type == 'stock_max' || type == 'stock_min') {
      //传进来剩余库存判断
      if (
        this.form.stock_min != '' &&
        this.form.stock_min != null &&
        (this.form.stock_min < 0 || !res.test(this.form.stock_min))
      ) {
        this.surplus_number = true;
      } else if (
        this.form.stock_max != '' &&
        this.form.stock_max != null &&
        (this.form.stock_max < 0 || !res.test(this.form.stock_max))
      ) {
        this.surplus_number = true;
      } else {
        this.surplus_number = false;
      }
      this.form[type] = +value.toFixed(0);
    } else {
      if (
        this.form.sales_min != '' &&
        this.form.sales_min != null &&
        (this.form.sales_min < 0 || !res.test(this.form.sales_min))
      ) {
        this.all_sales = true;
      } else if (
        this.form.sales_max != '' &&
        this.form.sales_max != null &&
        (this.form.sales_max < 0 || !res.test(this.form.sales_max))
      ) {
        this.all_sales = true;
      } else {
        this.all_sales = false;
      }
      this.form[type] = +value.toFixed(0);
    }
  }

  // 当页全选
  onSelectAll(e: any) {
    const checked = e.target.checked;
    if (checked) {
      this.selected_id_list = this.ware_table.detail.map((item: any) => item.id);
    } else {
      this.selected_id_list = [];
    }
  }

  // 选择商品分类
  onWareCategoryChange(value: any) {
    this.form.ware_category_id = value;
  }

  // 商品上/下架
  @changeLoading(['is_loading'])
  async handleStatusChange() {
    const res: any = await updateWareStatus(this.modal_value.item.status === 1 ? 2 : 1, [
      this.modal_value.item.id,
    ]);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.$message.success('编辑成功！');
    this.modal_value.is_show_status = false;
    await this.fetchFilterWareList(this.next_page);
    this.modal_value.is_show_status_modal = false;
  }
  modal_value: any = {
    modal_type: 1,
    data: {},
    item: {},
    is_show_coupon_detail: false,
    is_show_status_modal: false,
  };
  showStatusModal(item: any) {
    this.modal_value.is_show_status_modal = true;
    this.modal_value.item = item;
  }

  // 编辑排序值
  @changeLoading(['is_loading'])
  async handleSortChange(id: number, value: number) {
    if (value === null) {
      this.$message.error('修改失败');
      return;
    }
    if (!/^[0-9]+$/.test(value + '')) {
      this.$message.error('排序值须为>=0的整数值！');
      return;
    }
    const res: any = await updateWareSort(id, value);
    if (res.status !== 200) {
      return this.$message.error(res.message);
    }
    this.$message.success('修改成功');
    this.fetchFilterWareList(this.next_page);
  }

  // 编辑排序值
  @changeLoading(['is_loading'])
  async handleStockChange(item: any, value: number, index: number) {
    if (value === null) {
      return;
    }
    if (!/^[0-9]+$/.test(value + '')) {
      this.$message.error('剩余库存值需为大于等于0的整数值！');
      return;
    }
    const res: any = await updateStock(item.warehouse[index].id, value);
    if (res.status !== 200) {
      return this.$message.error(res.message);
    }
    this.$message.success('修改成功');
    this.fetchFilterWareList(this.next_page);
  }

  // 批量关联
  // @changeLoading(['is_loading'])
  // async handleAllWareConnect() {
  //   const res = await updateWareConnect([]);
  // }

  // 商品关联
  @changeLoading(['is_loading'])
  async wareRelevance() {
    if (this.selected_id_list.length <= 1) {
      this.$message.error('商品关联至少需要勾选两个商品');
      return;
    }
    let ware_id_arr: number[] = [];
    this.selected_data.map((item: any) => {
      if (item.relevance.length >= 1) {
        const arr = item.relevance.map((item2: any) => item2.id);
        ware_id_arr.push(...arr);
      } else {
        ware_id_arr.push(item.id);
      }
    });
    ware_id_arr = [...new Set(ware_id_arr)];
    const res: any = await wareConnect(ware_id_arr);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    await this.fetchFilterWareList(this.next_page);
    this.selected_id_list = [];
    this.selected_data = [];
    this.$message.success('关联成功');
  }

  // 解除关联
  async handleWareDisconnect(record: any) {
    const res: any = await disconnect(record.id);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.modal_ware_table_data.splice(
      _.findIndex(this.modal_ware_table_data, (item: any) => item.id === record.id),
      1
    );
    this.$message.success('解除关联成功');
  }

  // 批量上架
  @changeLoading(['is_loading'])
  async batchAddedOrOutOfStock(type: number) {
    // type 1上架 2下架
    if (this.selected_id_list.length < 1) {
      this.$message.error('请勾选商品');
      return;
    }
    const res: any = await updateWareStatus(type, this.selected_id_list);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    await this.fetchWareList();
    await this.query();
    this.selected_id_list = [];
    this.selected_data = [];
  }

  async close_ware_modal() {
    this.is_show_ware_modal = false;
    await this.fetchFilterWareList(this.next_page);
  }
  // 查看商品关联
  async handleWatchWareConnect(item: any) {
    this.is_show_ware_modal = true;
    this.modal_ware_table_data = [];
    const id_arr = item.relevance.map((item2: any) => item2.id);
    const res: any = await queryWareConnect(id_arr);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.modal_ware_table_data = res.data;
    this.modal_ware_table_data.forEach(x => {
      const img_arr = x.viewpager_url.split(',');
      x.viewpager_url = img_arr[0];
    });
  }

  async submit(e) {
    e.preventDefault();
    this.searchForm.validateFields(async (err, values) => {
      if (!err) {
        console.log(values);
        this.form = {
          ...values,
        };
        this.query();
      }
    });
  }
  // 搜索
  async query() {
    this.selected_id_list = [];
    this.selected_data = [];
    await this.fetchFilterWareList(this.ware_table.current);
  }

  @changeLoading(['is_loading'])
  async fetchFilterWareList(current: number = 1, stock?: string, sales?: string) {
    const send_data: any = {
      ...this.form,
      warehouse_id: this.form.warehouse_id ? this.form.warehouse_id : '',
      is_show: this.form.is_show === 0 ? '' : this.form.is_show,
      is_recommend: this.form.is_recommend === 0 ? '' : this.form.is_recommend,
      is_relevance: this.form.is_relevance === 0 ? '' : this.form.is_relevance,
      start_time: this.form.create_time[0] ? this.form.create_time[0].format('X') : '',
      end_time: this.form.create_time[1] ? this.form.create_time[1].format('X') : '',
      price_min: this.form.price_min !== '' ? this.form.price_min * 100 : '',
      price_max: this.form.price_max !== '' ? this.form.price_max * 100 : '',
      category_id:
        this.form.ware_category_id[0] === 0
          ? ''
          : this.form.ware_category_id[this.form.ware_category_id.length - 1],
      stock_sort: stock,
      sales_sort: sales,
    };
    // console.log('send_data', JSON.parse(JSON.stringify(send_data)));
    const clean = require('bmh/clean-empty-obj');

    clean(send_data);

    // console.log('send_data', send_data);
    this.ware_table = await queryWare(current, send_data);
    this.getParentCategory();
    this.all_ware_data[this.next_page] = this.ware_table.detail;
    // console.log(this.ware_table.detail);
  }
  // 获取商品父级分类
  getParentCategory() {
    this.ware_table.detail.forEach((ware: any) => {
      this.ware_category_list.map((ware_category: any) => {
        if (ware_category.id === ware.category.parent_id && ware.category.parent_id !== 0) {
          ware.category.parent_category = `${ware_category.name} /`;
        }
      });
    });
  }

  // 获取仓库列表
  async fetchRepoList() {
    const res: any = await queryRepo();
    this.repo_list = res.map((item: any) => {
      return {
        id: item.warehouse_id,
        name: item.repo_name,
      };
    });
    this.repo_list.unshift({
      id: 0,
      name: '全部',
    });
  }

  // 获取商品分类列表
  async fetchWareCategoryList() {
    this.ware_category_list = await getSimpleCategory();
    this.ware_category_list.unshift({
      id: 0,
      name: '全部',
    });
  }

  // 获取商品列表
  async fetchWareList(current: number = 1) {
    this.ware_table = await queryWare(current);
    this.all_ware_data[this.next_page] = this.ware_table.detail;
    this.getParentCategory();
    this.$forceUpdate();
    //console.log(JSON.parse(JSON.stringify(this.ware_table.detail)) )
  }

  @changeLoading(['is_loading'])
  async created() {
    this.searchForm = (AntForm as any)?.createForm(this, {
      name: 'form',
    });
    if (this.pagination?.managment) {
      Object.keys(this.pagination.managment).forEach(item => {
        if (item !== 'current') {
          this.form[item] = this.pagination.managment[item];
        } else {
          this.ware_table.current = this.pagination.managment[item];
        }
      });
      // await this.getAccountList(this.pagination?.managment);
      this.query();
      delete this.pagination.managment;
      return;
    }
    await this.fetchWareCategoryList();
    await this.fetchWareList(this.$route.query.page ? +this.$route.query.page : 1);
    await this.fetchRepoList();
    await this.setGlobalFunction({ fetchWareList: this.fetchWareList });
  }
  onEdit() {
    this.pagination.managment = {
      ...this.form,
      current: this.ware_table.current,
    };
  }
}
